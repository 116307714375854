<template>
    <section class="container-fluid ">
        <div class="row " data-aos="fade-up">
            <div class="col-12 ">
                <div class="row d-none d-md-flex">
                    <div class="col-4">
                        <div v-for="(item,x) in col1" :key="x" class="pb-4 p-1">
                            <router-link :to="'/artwork/'+item.slug">
                                <img  :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" style="width:100%" :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p v-show="false" style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>

                    <div class="col-4">
                        <div v-for="(item,y) in col2" :key="y" class="pb-4 p-1">
                            <router-link :to="'/artwork/'+item.slug">
                                <img  :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" style="width:100%" :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p v-show="false"  style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-4">
                        <div v-for="(item,y) in col3" :key="y" class="pb-4 p-1">
                            <router-link :to="'/artwork/'+item.slug">
                                <img :src="$url+'/'+item.featured" class="img-fluid cursor-pointer" style="width:100%"  :alt="item.title"/>
                                <h3 v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                                <p  v-show="false" style="white-space:pre-line">
                                    {{ item.year }}<br>
                                    {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
                                    {{ item.dimensions }}
                                </p>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="row d-md-none">
                    <div class="col-12 pb-3" v-for="(item,x) in all" :key="x" >
                        <router-link :to="'/artwork/'+item.slug">
                            <img  :src="$url+'/'+item.featured" class="img-fluid" :alt="item.title"/>
                            <h3  v-show="false" class="subtitle pb-0 pt-0">{{ item.title }}</h3>
                            <p  v-show="false" style="white-space:pre-line">
                                {{ item.year }}<br>
                                {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
                                {{ item.dimensions }}
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'FigurativeComponent',
    data(){
        return{
            col1:{},
            col2:{},
            col3:{},
            all:{},
        }
    },
    created(){
        this.getArtworks()
    },
  
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/figurative',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.col1 = res.data.col1
                this.col2 = res.data.col2
                this.col3 = res.data.col3
                this.all = res.data.all
            }).catch((err)=>{
                console.log(err)
            })
        },
        
    },
    
}
</script>