import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('lang') == '' ? navigator.languages[1] :  localStorage.getItem('lang')
  },
  mutations: {
    setLang(state,lang){
      state.lang = lang
    }
  },
  actions: {
    saveLang({commit},lang){
      commit('setLang',lang)
      localStorage.setItem('lang',lang)
    }
  }
})
