<template>
    <div>
        <section class="container card" >
            <Transition  name="fade" appear >
                <div class="row " >
                    <div class="col-12 col-md-8 m-auto" data-aos="fade-up">
                        <h2 class="subtitle" v-if="$store.state.lang == 'es'">Educación:</h2>
                        <h2 class="subtitle" v-else>Education:</h2>
                        <div style="display: flex; flex-direction: column-reverse;">
                            <div v-for="(year,x) in profesional" :key="x" class="p-2" >
                                <div class="row" >
                                    <div class="col-md-1 col-2 text-nowrap"><p><span>{{ x }}</span></p></div>
                                    <div class="col-md-11 col-10">
                                        <p v-for="(ex,y) in year" :key="y" class="pb-5 pb-md-1"> 
                                            {{$store.state.lang == 'en' ? ex.degree_en : ex.degree_es }}, {{ex.institution }}. {{ ex.place }}, {{ ex.country }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column-reverse;">
                            <div v-for="(year,x) in studies" :key="x" class="p-2" >
                                <div class="row" >
                                    <div class="col-md-1 col-2 text-nowrap"><p><span>{{ x }}</span></p></div>
                                    <div class="col-md-11 col-10">
                                      
                                        <p v-for="(ex,y) in year" :key="y" class="pb-5 pb-md-1"> 
                                            {{$store.state.lang == 'en' ? ex.degree_en : ex.degree_es }}, {{ex.institution }}. {{ ex.place }}, {{ ex.country }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-md-8 m-auto"  data-aos="fade-up">
                        <h2 class="subtitle" v-if="$store.state.lang == 'es'">Exposiciones colectivas:</h2>
                        <h2 class="subtitle" v-else>Selected Group exhibitions:</h2>
                        <div style="display: flex; flex-direction: column-reverse;">
                            <div v-for="(year,x) in exhibitions" :key="x" class="p-2" >
                                <div class="row" >
                                    <div class="col-md-1 col-2 text-nowrap"><p><span>{{ x }}</span></p></div>
                                    <div class="col-md-11 col-10">
                                        <p v-for="(ex,x) in year" :key="x" class="pb-5 pb-md-1"> 
                                            {{ ex.name }}{{ex.gallery != null ? ', '+ex.gallery : '' }}. {{ ex.city }}, {{ ex.country }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-8 m-auto" data-aos="fade-up" >
                        <h2 class="subtitle" v-if="$store.state.lang == 'es'">Publicaciones:</h2>
                        <h2 class="subtitle" v-else>Publishing:</h2>
                        <div style="display: flex; flex-direction: column-reverse;">
                            <div v-for="(year,x) in books" :key="x" class="p-2" >
                                <div class="row">
                                    <div class="col-md-1 col-2 text-nowrap"><p><span>{{ x }}</span></p></div>
                                    <div class="col-md-11 col-10">
                                        <p v-for="(book,y) in year" :key="y" class="pb-5 pb-md-1"> 
                                            {{ book.title }},{{book.publisher }}. {{ book.author }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-8 m-auto" data-aos="fade-up" >
                        <h2 class="subtitle" v-if="$store.state.lang == 'es'">Prensa:</h2>
                        <h2 class="subtitle" v-else>Press:</h2>
                        <div style="display: flex; flex-direction: column-reverse;">
                            <div v-for="(year,x) in press" :key="x" class="p-2" >
                                <div class="row">
                                    <div class="col-md-1 col-2 text-nowrap"><p><span>{{ x }}</span></p></div>
                                    <div class="col-md-11 col-10">
                                        <p v-for="(news,y) in year" :key="y" class="pb-5 pb-md-1"> 
                                            {{ news.title }},{{news.publisher }}. {{ news.author }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>                    
                </div>
            </Transition>
        </section>
    </div>
</template>

<script >
import moment from 'moment';
import axios from 'axios'
import {_} from 'vue-underscore';
export default {
    name: 'ArtistComponent',

    data(){
        return{
            artist: 'Marina SaMont',
            profesional: {},
            exhibitions: {},
            studies: {},
            books:{},
            press:{},
            content:[]
        }
    },
    created(){
        this.getArtistData()
        this.getExhibitions()
        this.getProfesional()
        this.getStudies()
        this.getBooks()
        this.getPress()
    },
  
    methods:{
        format(date,format){
            return moment(date).format(format)
        },

        getArtistData(){
            axios.get(this.$url+'/artist',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.content = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getExhibitions(){
            axios.get(this.$url+'/exhibitionsp',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.exhibitions = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getProfesional(){
            axios.get(this.$url+'/profesional',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.profesional = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getStudies(){
            axios.get(this.$url+'/studies',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.studies = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getBooks(){
            axios.get(this.$url+'/books',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.books = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        getPress(){
            axios.get(this.$url+'/press',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.press = res.data
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
}
</script>