<template>
    <section class="container">
        <Transition  name="fade" appear >
            <div class="row top p-3">
                <div class="col-12">
                    <p class="text-center"><strong>TERMS AND CONDITIONS</strong></p>
                    <p><br>This contract describes the general terms and conditions (hereinafter only “TERMS AND CONDITIONS”) applicable to the use of the content, products and services offered through the site <strong>www.marinasamont.com</strong> (hereinafter, “WEBSITE”), of which is the owner <strong>Marina SaMont</strong> (hereinafter, “HOLDER”). Any person who wishes to access or make use of the site or the services offered therein, may do so subject to these TERMS AND CONDITIONS, as well as the policies and principles incorporated into this document. In any case, any person who does not accept these terms and conditions must refrain from using the WEBSITE and / or acquiring the products and services that may be offered.</p>
                    <p><strong><br></strong><strong>I. OF THE OBJECT.</strong></p>
                    <p>The purpose of these TERMS AND CONDITIONS is to regulate the access and use of the WEBSITE, understood as any type of content, product or service that is available to the general public within the domain: <strong>www.marinasamont.com</strong>.</p>
                    <p>The OWNER reserves the right to modify at any time and without prior notice, the presentation, the contents, the functionality, the products, the services, and the configuration that may be contained in the WEBSITE; In this sense, the USER acknowledges and accepts that <strong>Marina SaMont</strong> may at any time interrupt, deactivate or cancel any of the elements that make up the WEBSITE or access to them.</p>
                    <p>In addition to the cost of the internet connection by virtue of the services that the USER has contracted with a telecommunications provider, part of the content or services offered on the site www.marinasamont.com or, where appropriate, by third parties through the WEBSITE may be subject to prior contracting of the content, product or service, in which case the general or particular conditions governing access to said content will be clearly specified and made available to the USER.</p>
                    <p>Access to part of the contents and services of the WEBSITE may be made with prior subscription or prior registration of the USER.</p>
                    <p>The WEBSITE is aimed exclusively at people who are of legal age (over 18 years of age); In this sense, <strong>Marina SaMont</strong> declines any responsibility for the breach of this requirement.</p>
                    <p>The WEBSITE is aimed mainly at USERS residing in the Mexican Republic, for which reason, <strong>Marina SaMont</strong> does not ensure that the WEBSITE fully or partially complies with the legislation of other countries, so that, if the USER resides or has an established address in another country and you decide to access or use the WEBSITE, you will do so under your own responsibility and you must ensure that such access and navigation complies with the local legislation that is applicable to you, with<strong> Marina SaMont </strong>not assuming any responsibility that may arise from such act.</p>
                    <p>The USER is made aware that the OWNER may administer or manage the WEBSITE directly or through a third party, which does not modify in any way what is established in these TERMS AND CONDITIONS.</p>
                    <p><strong><br></strong><strong>II. OF THE USER.</strong></p>
                    <p>Access to or use of the WEBSITE confers the condition of USER of the WEBSITE, which is why it will be subject to these TERMS AND CONDITIONS, as well as its subsequent modifications, without prejudice to the application of the applicable legislation, therefore, They will be considered accepted from the moment the WEBSITE is accessed. Given the relevance of the foregoing, the USER is recommended to review the updates made to these TERMS AND CONDITIONS.</p>
                    <p>It is the USER’s responsibility to use the WEBSITE according to the way it was designed; In this sense, the use of any type of software that automates the interaction or download of the contents or services provided through the WEBSITE is prohibited. In addition, the USER agrees to use the information, content or services offered through the WEBSITE in a lawful manner, without contravening the provisions of these TERMS AND CONDITIONS, morality or public order, and will refrain from performing any act that It may imply an infringement of the rights of third parties, or in any way impair the functioning of the WEBSITE.</p>
                    <p>Likewise, the user undertakes to provide lawful and truthful information in the forms enabled on the WEBSITE, in which the user has to provide certain data or information to access any content, product or service offered by the WEBSITE itself. . In any case, the USER will immediately notify the OWNER about any fact that allows the misuse of the information registered in said forms, such as theft, loss, or unauthorized access to accounts and / or passwords, with the in order to proceed with its immediate cancellation.</p>
                    <p>Only access to the WEBSITE does not imply the establishment of any type of relationship between the OWNER and the USER.</p>
                    <p>As it is a WEBSITE aimed exclusively at people who are of legal age, the USER declares that they are of legal age and have the necessary legal capacity to be bound by these TERMS AND CONDITIONS.</p>
                    <p><strong><br></strong><strong>III. ACCESS AND NAVIGATION ON THE WEBSITE.</strong></p>
                    <p>The OWNER does not guarantee in any way the continuity and availability of the contents, products or services offered through the WEBSITE, however, the OWNER will carry out the actions that, according to their possibilities, allow them to maintain the proper functioning of the WEBSITE. , without this implying any responsibility on the part of <strong>Marina SaMont</strong>.</p>
                    <p>In the same way, <strong>Marina SaMont</strong> will not be responsible or guarantee that the content or software that can be accessed through the WEBSITE is free of errors, malicious software, or that it may cause any damage at the software or hardware level to the computer to through which the USER accesses the WEBSITE.</p>
                    <p>The OWNER is not responsible for any damages that may be caused by improper use of the WEBSITE. In no case will <strong>Marina SaMont</strong> be responsible for losses, damages or damages of any kind that arise from the sole access or use of the WEBSITE.</p>
                    <p><strong><br></strong><strong>IV. PRIVACY POLICY AND DATA PROTECTION.</strong></p>
                    <p>In accordance with the provisions of the Federal Law on Protection of Personal Data Held by Private Parties, the HOLDER undertakes to adopt the necessary measures that are within its reach to ensure the privacy of the personal data collected in a way that guarantees its security, its alteration, loss or unauthorized treatment is avoided.</p>
                    <p>In addition, in order to comply with the provisions of the Federal Law on Protection of Personal Data Held by Private Parties, all personal data that is collected through the WEBSITE will be treated in accordance with the principles of legality, quality, purpose, loyalty, and responsibility. All processing of personal data will be subject to the consent of the owner. In any case, the use of financial or patrimonial data will require the express authorization of their owners, however, this may be given through the WEBSITE itself using the mechanisms enabled for that purpose, and in any case the greatest diligence and Be careful with this type of data. The same will happen in the case of sensitive personal data, considering by these those that due to improper use may give rise to discrimination or their disclosure entails a risk for the owner.</p>
                    <p>At all times it will be ensured that the personal data contained in the databases or files that are used, where appropriate, are relevant, correct and updated for the purposes for which they were collected.</p>
                    <p>The processing of personal data will be limited to the fulfillment of the purposes set forth in the Privacy Notice, which will be available at the following electronic address:</p>
                    <p><router-link to="/privacy-policy" class="external-link">www.marinasamont.com/privacy-policy</router-link></p>
                    <p>The WEBSITE may include hyperlinks or links that allow access to web pages of third parties other than <strong>Marina SaMont</strong>. The owners of these websites will have their own privacy and data protection policies, for which <strong>Marina SaMont</strong> does not assume any type of responsibility for the data that is provided by the USER through any website other than <strong>www.marinasamont.com</strong>.</p>
                    <p><strong>Marina SaMont</strong> reserves the right to modify its Privacy Policy, according to its needs or derived from any change in the legislation. Access or use of the WEBSITE after said changes will imply acceptance of these changes.</p>
                    <p>On the other hand, access to the WEBSITE may involve the use of cookies, which are small amounts of information that are stored in the browser used by the USER. Cookies facilitate navigation, make it more friendly, and do not damage the navigation device, for this, they can collect information to enter the WEBSITE, store the USER’s preferences, as well as the interaction that the USER has with the WEBSITE, such as for example: the date and time on which the WEBSITE is accessed, the time that it has been used, the sites visited before and after it, the number of pages visited, the IP address from which the user accesses , the frequency of visits, etc.</p>
                    <p>This type of information will be used to improve the WEBSITE, detect errors, and possible needs that the USER may have, the above in order to offer USERS better quality services and content. In any case, the information collected will be anonymous and individual users will not be identified.</p>
                    <p>In the event that the USER does not want this type of information to be collected, they must disable, reject, restrict and / or eliminate the use of cookies in their internet browser. The procedures to perform these actions may differ from one browser to another; Consequently, it is suggested to review the instructions provided by the browser developer. In the event that you reject the use of cookies (totally or partially), the USER may continue to use the WEBSITE, although some of its functions may be disabled.</p>
                    <p>It is possible that in the future these policies regarding cookies will change or be updated, so it is advisable to review the updates made to these TERMS AND CONDITIONS, in order to be adequately informed about how and why we use the cookies that are generated when entering or using the WEBSITE.</p>
                    <p><strong><br></strong><strong>V. LINKS POLICY.</strong></p>
                    <p>The WEBSITE may contain links, content, services or functions of other internet sites owned and / or managed by third parties, such as images, videos, comments, search engines, etc.</p>
                    <p>The use of these links, content, services or functions is intended to improve the USER’s experience when using the WEBSITE, without it being considered a suggestion, recommendation or invitation to use external sites. <strong>Marina SaMont</strong> in no case will review or control the content of external sites, in the same way, it does not endorse the products, services, content, and any other existing material on the aforementioned linked sites; Therefore, the availability, accuracy, veracity, validity or legality of external sites that can be accessed through the WEBSITE will not be guaranteed either. Likewise, the OWNER does not assume any responsibility for damages that may occur due to the access or use of the contents, products or services available on the websites not managed by <strong>Marina SaMont</strong> that can be accessed through the WEBSITE .</p>
                    <p>USERS or third parties who make or publish a web link from an external web page to this WEBSITE must take into account the following:</p>
                    <p>The reproduction (total or partial) of the contents, products or services available on the WEBSITE is not allowed without the express authorization of <strong>Marina SaMont</strong> or its owner. Neither will false, inaccurate or incorrect statements be allowed on the WEBSITE, nor on its contents, products or services, and <strong>Marina SaMont</strong> may restrict access to the WEBSITE to anyone who engages in this type of act.</p>
                    <p>The establishment of a link to the WEBSITE from any external site will not imply the existence of any relationship between <strong>Marina SaMont</strong> and the owner of the website from which it is made, nor will it imply <strong>Marina SaMont’s</strong> knowledge of the contents, products or services offered. on external sites from which the WEBSITE can be accessed.</p>
                    <p><strong><br></strong><strong>VI. POLICY REGARDING INTELLECTUAL AND INDUSTRIAL PROPERTY.</strong></p>
                    <p><strong>Marina SaMont </strong>by itself or as a transferee, is the owner of all the intellectual and industrial property rights of the WEBSITE, understanding by this the source code that makes its operation possible as well as the images, audio or video files, logos, brands, combinations of colors, structures, designs and other elements that distinguish it. They will, therefore, be protected by Mexican legislation on intellectual and industrial property, as well as by applicable international treaties. Consequently, the reproduction, distribution, or dissemination of the contents of the WEBSITE, for commercial purposes, on any medium and by any means, without the authorization of <strong>Marina SaMont</strong>, is expressly prohibited.</p>
                    <p>The USER undertakes to respect the intellectual and industrial property rights of the HOLDER. However, in addition to being able to view the elements of the WEBSITE, you may print, copy or store them, as long as it is exclusively for your strictly personal use.</p>
                    <p>On the other hand, the USER will refrain from deleting, altering, or manipulating any element, file, or content, of the WEBSITE, and for no reason will carry out acts tending to violate the security, files or databases that are protected , either through restricted access by means of a username and password, or because they do not have the permissions to view, edit or manipulate them.</p>
                    <p>In the event that the USER or any third party considers that any of the contents of the WEBSITE constitutes a violation of the rights of protection of industrial or intellectual property, they must immediately notify <strong>Marina SaMont</strong> through the contact information available on the website. WEBSITE and / or through the following means:</p>
                    <p>Email: hello@marinasamont.com</p>
                    <p><strong><br></strong><strong>VII. APPLICABLE LEGISLATION AND JURISDICTION.</strong></p>
                    <p><strong>Marina SaMont </strong>reserves the right to file the civil or criminal actions it deems necessary for the improper use of the WEBSITE, its contents, products or services, or for the breach of these TERMS AND CONDITIONS.</p>
                    <p>The relationship between the USER and <strong>Marina SaMont</strong> will be governed by current legislation in Mexico, specifically in Nuevo León. Should any controversy arise in relation to the interpretation and / or application of these TERMS AND CONDITIONS, the parties will submit to the ordinary jurisdiction of the corresponding courts in accordance with the law in the state referred to.</p>
                    </div>
            </div>
        </Transition>
    </section>
</template>

<script>
export default {
    name: 'TermsCondition'
}
</script>