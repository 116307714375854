<template>
    <section class="container">
        <div class="row  card" >
          
            <div class="row m-auto"  data-aos="fade-up">
                <div class="col-md-8 col-12" >
                    <img  :src="$url+'/'+item.featured" class="img-fluid" :alt="item.title"/>
                </div>
                <div class="col-md-4 col-12 " >
                    <p  class="subtitle">{{ item.title }}</p>
                    <p style="font-size:13px !important" class="text-lowercase">
                        {{ $store.state.lang == 'es' ? item.media_es : item.media_en }}<br>
                        {{ item.dimensions }}<br>
                        {{ item.year }}
                    </p>
                    <p  class="subtitle">{{ $store.state.lang == 'es' ? 'Obra Original' : 'Original Artwork' }}</p>
                    <ul v-if="$store.state.lang == 'es'" style="color:#959595" class="text-italic">
                        <li>Certificado de Autenticidad</li>
                        <li>No incluye marco</li>
                        <li v-if="item.shipping_package == 'tube'">Se envía enrollado en tubo</li>
                        <li v-if="item.shipping_package == 'box'">Se envía en caja</li>
                        <li v-if="item.shipping_package == 'envelope'">Se envía en sobre</li>
                    </ul>
                    <ul v-else style="color:#959595">
                        <li>Certificate of Authenticity</li>
                        <li>Frame not included</li>
                        <li v-if="item.shipping_package == 'tube'">Ships in a tube</li>
                        <li v-if="item.shipping_package == 'box'">Ships in a box</li>
                        <li v-if="item.shipping_package == 'envelope'">Ships in an envelope</li>
                    </ul>
                    <div  class="pt-5" v-show="false">
                        <button type="button" class="btn btn-sm btn-red title-subtitle" @click="buy()" v-if="$store.state.lang == 'en'">Buy Artwork</button>
                        <button type="button" class="btn btn-sm btn-red title-subtitle" @click="buy()" v-else>Comprar Pintura</button>
                    </div>

                    <div class="pt-5" v-show="false">
                        <div v-if="!atCart">
                            <button type="button" class="btn btn-sm btn-black title-subtitle" @click="addToCart()" v-if="$store.state.lang == 'es'">Agregar al Carrito</button>
                            <button type="button" class="btn btn-sm btn-black title-subtitle" @click="addToCart()" v-else>Add to Cart</button>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-sm btn-gray title-subtitle" @click="removeFromCart()" v-if="$store.state.lang == 'es'">Eliminar del Carrito</button>
                            <button type="button" class="btn btn-sm btn-gray title-subtitle" @click="removeFromCart()" v-else>Remove from Cart</button>
                        </div>
                    </div>
                    <div class="border p-3 mt-5" >
                        <h6 class="subtitle pt-0" v-if="$store.state.lang == 'es'">Representante de ventas:</h6> 
                        <h6 class="subtitle pt-0" v-else>Sales Rep.:</h6>
                        <p style="font-size:13px !important" >
                            <span v-if="$store.state.lang == 'es'">Para conocer las políticas de venta y/o comprar la obra comuníquese con</span>
                            <span v-else>For shop policy and/or buy the artwork please contact</span>
                            <br>{{ contact.name }}<br>
                            <span v-if="contact.whatsapp"><a class=" btn btn-black text-uppercase" target="_blank" :href="'https://wa.me/'+contact.whatsapp+'?text=Im%20interested%20in%20the%20next%20artwork%20:'+item.title.replace(' ','%20')+'%20by%20Marina%20SaMont'"><Whatsapp /> Get more info</a></span><br>
                            <span v-if="contact.phone">Phone:<a class="link" target="_blank" :href="'tel:'+contact.phone">{{ contact.phone }}</a></span><br>
                            <span v-if="contact.email">email:<a class="link" target="_blank" :href="'mailto:'+contact.email">{{ contact.email.replace('@','[at]') }}</a></span><br>
                            <span v-if="contact.location">{{ contact.location }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-8 col-12 offset-md-2" v-for="(image,z) in images" :key="z">
                    <img :src="$url+'/'+image" class="img-fluid" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import InstagramIcon from 'vue-material-design-icons/Instagram.vue'
import Whatsapp from 'vue-material-design-icons/Whatsapp.vue'
import axios from 'axios'
export default {
    name: 'SalesContactComponent',
    components: { FacebookIcon, InstagramIcon ,Whatsapp},
    data(){
        return{
            item:{},
            images:[],
            contact:{}
        }
    },
    created(){
        this.getArtworks()
    },
    computed:{
        atCart(){
           return this.$store.state.cart.find((e) => {
                return e.slug == this.item.slug
            })
        }
    },
    methods:{
        buy(){
            this.clearCart()
            this.addToCart()
            this.$router.push('/checkout')
        },
        clearCart(){
            let cart = []
            this.$store.dispatch('saveCart',cart)
        },
        addToCart(){
            let cart = this.$store.state.cart
            cart.push(this.item)
            this.$store.dispatch('saveCart',cart)
        },
        removeFromCart(){
            let cart = this.$store.state.cart
            let indx = cart.find(e=>e.slug == this.item.slug)
            cart.splice(cart.indexOf(indx), 1);
            this.$store.dispatch('saveCart',cart)
        },
        async getArtworks(){
            await axios.get(this.$url+'/shopitem/'+this.$route.params.slug,{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.item = res.data.artwork
                this.contact = res.data.contact
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
}
</script>