<template>
    <section class="container">
        <Transition  name="fade" appear >
            <div class="row top p-3">
                <div class="col-12">
                    <p class="text-center"><strong>PRIVACY OF POLICY</strong></p>
                    <p>In accordance with the provisions of the Federal Law on Protection of Personal Data Held by Private Parties, <strong>Marina SaMont</strong> makes the following privacy notice available to you.</p>
                    <p><strong><br></strong><strong>Marina SaMont,</strong> is responsible for the use and protection of your personal data, in this sense and in compliance with the legal obligations established in the Federal Law on Protection of Personal Data Held by Individuals, through this instrument the holders of the data, the information that is collected from them and the purposes that will be given to such information.</p>
                    <p><br>In addition to the above, we inform you that <strong>Marina SaMont</strong> has its address located at:</p>
                    <ul><li>Nuevo León, México</li></ul>
                    <p>We at marinasamont.com use Google Analytics to collect data. We need this data to understand how you use our website so we can improve its design and functionality. We also need the data to get the most out of our marketing campaigns.</p>
                    <p>With your consent, Google Analytics will process and collect your personal data (cookies and IP address) to give us 
                        valuable information. Google Analytics will transfer your data to the United States and store it for 6 months. </p>
                    <p>Standard contractual clauses are legal clauses written by the European Commission. 
                        They are part of a contract between Google Ireland Ltd. and Google LLC, and Google LLC must follow them. 
                        Standard contractual clauses tell Google LLC what it can and cannot do with your data.</p>
                    <p>You have certain rights over your data: for example, you can require us to delete them or to provide you with a copy. 
                        We take responsibility for the processing of your data. We are available to answer any question and handle any request 
                        from you. </p>
                    <p>By navigating through this website you accept the next cookie preference: I consent to the processing of non-essential cookies
                    </p><p>We will still collect some data if you do not consent.</p>
                    <p><br>The personal data that we collect from you will be used for the following purposes, which are necessary to specify our relationship with you as well as attend the services and / or orders that you request:</p>
                    <ul><li>Provide and improve our services</li></ul>
                    <p><br>To carry out the purposes described in this privacy notice, we will use the following personal data:</p>
                    <ul><li>Cookies</li><li>Personal Data</li></ul>
                    <p><br>On the other hand, we inform you that your personal data will not be shared with any authority, company, organization or person other than us and will be used exclusively for the indicated purposes.</p>
                    <p><br>You have at all times the right to know what personal data we have about you, what we use them for and the conditions of use that we give them (Access). Likewise, it is your right to request the correction of your personal information if it is outdated, inaccurate or incomplete (Rectification); Similarly, you have the right to have your information removed from our records or databases when you consider that it is not being used properly (Cancellation); as well as to oppose the use of your personal data for specific purposes (Opposition). These rights are known as ARCO rights.</p>
                    <p><br>For the exercise of any of the ARCO rights, the respective request must be submitted through the following email:</p>
                    <ul><li>hello@marinasamont.com</li></ul>
                    <p><br>The foregoing will also serve to know the procedure and requirements for the exercise of ARCO rights, however, the request to exercise these rights must contain the following information:</p>
                    <ul><li>Name:</li><li>Telephone:</li><li>Email:</li><li>Reason for the request</li></ul>
                    <p><br>The response to the request will be given in 20 business days and will be communicated as follows:</p>
                    <ul><li>Through the email indicated in the request</li></ul>
                    <p><br>The contact details of the person or department of personal data, which is in charge of processing the ARCO rights requests, are the following:</p>
                    <p><br>a) Name of the person in charge: Information Department</p>
                    <p>b) Address: Nuevo León, México</p>
                    <p>c) Email: hello@marinasamont.com</p>
                    <p><br>It is worth mentioning that at any time you can revoke your consent for the use of your personal data. In the same way, you can revoke the consent that, where appropriate, you have given us for the processing of your personal data. However, it is important that you bear in mind that not in all cases we will be able to respond to your request or terminate the use immediately, since it is possible that due to some legal obligation it is required to continue treating your personal data. Likewise, you should consider that for certain purposes, the revocation of your consent will imply that we cannot continue to provide the service you requested, or the conclusion of your relationship with us.</p>
                    <p><br>To revoke the consent that you grant in this act or to limit its disclosure, the respective request must be submitted through the following email:</p>
                    <ul><li>hello@marinasamont.com</li></ul>
                    <p><br>In the same way, you may request the information to know the procedure and requirements for the revocation of consent, as well as limit the use and disclosure of your personal information.</p>
                    <p><br>In any case, the response to the requests will be announced within 20 business days.</p>
                    <p><br>This privacy notice may undergo modifications, changes or updates derived from new legal requirements; of our own needs for the products or services we offer; of our privacy practices; of changes in our business model, or for other reasons, for which, we promise to keep you informed about the changes that this privacy notice may undergo, however, you can request information about whether it has undergone any change to via the following email:</p>
                    <ul><li>hello@marinasamont.com</li></ul>
                    <p class="text-end"><br>Last update:</p>
                    <p class="text-end">08/10/2021</p>
                </div>
            </div>
        </Transition>
    </section>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>