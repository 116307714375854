<template>
    <section class="container-fluid shop">
        <div class="row "><div class="col-12"></div></div>
        
        <Transition  name="fade" appear v-if="show">
            <div class="row pb-5"  >
                <div class="text-center col-12 title-contact" >
                    <div  data-aos="fade-left" >
                        <h1 v-if="$store.state.lang == 'en'" class="shop-title title-subtitle">Personalized artwork, as the artist interprets your thoughts…</h1>
                        <h1 v-else class="shop-title title-subtitle">Obra de arte personalizada, como la artista interpreta tus pensamientos…</h1>
                    </div>
                </div>
            </div>  
        </Transition>
    
        <div class="row" data-aos="fade-up" >
            <div class="col-12 col-md-7 m-auto" v-if="show">
                <h4 v-if="$store.state.lang == 'en'" style="font-weight:400;line-height:2.5rem " class="text-center">
                    Personalized paintings offer a deeper connection and a new way of interpretation from your own dreams. 
                    This artwork will be unique and original created only for you, birthed from your symbology.
                </h4>
                <h4 v-else style="font-weight:400;line-height:2.5rem  " class="text-center">
                    Las obras personalizadas ofrecen una conexión más profunda y una nueva forma de interpretación de tus propios sueños. 
                    Esta obra de arte será única y original creada solo para ti, nacido de tu simbología.
                </h4>
                <br>
                <h4 v-if="$store.state.lang == 'en'" style="font-weight:400;line-height:2.5rem "  class="text-center">
                    Remember that a personalized painting comes out from my interpretation of your dreams. Symbols and some times models 
                    will come out from you but keep ideas open for my interpretation. Let this special piece be part of your collection.
                </h4>
                <h4 v-else style="font-weight:400;line-height:2.5rem "  class="text-center">
                    Recuerda que una obra personalizada sale de mi interpretación de tus sueños. Símbolos y algunas veces modelos
                    saldrán de ti pero mantén las ideas abiertas para mi interpretación. Deja que esta pieza especial forme parte de tu colección.
                </h4>
                <br>
                <h4 v-if="$store.state.lang == 'en'" style="font-weight:400;line-height:2.5rem "  class="text-center">
                    <b>I do not accept cryptocurrency of any kind for any reason.</b>
                    Due to high demand, I am only accepting commission requests with a deposit. 
                    Custom paintings/sketches take longer to produce (1-6 months). 
                    Available to ship worldwide. This deposit is non-refundable. If you decide not to go through with 
                    the commission after paying, you can apply the payment towards any other available paintings on my shop
                </h4>
                <h4 v-else style="font-weight:400;line-height:2.5rem "  class="text-center">
                    <b>No acepto criptomonedas de ningun tipo por ningun motivo.</b>
                    Debido a la gran demanda, solo acepto solicitudes de obra personalizada con un anticipo. 
                    Las pinturas/bocetos personalizados tardan más en producirse (de 1 a 6 meses). 
                    Disponible para envíos a todo el mundo. Este depósito no es reembolsable. Si decides no continuar con 
                    la obra personalizada después de pagar, puedes aplicar el pago a cualquier otra pintura disponible en mi tienda.
                </h4>
                <br>
                <h4 v-if="$store.state.lang == 'en'" style="font-weight:400;line-height:2.5rem "  class="text-center">
                    Portraits are the most pieces I receive as a commission. A portrait consists of a single person painting. 
                    Will be an extra charge for each extra person in the painting.<br>
                    Here's a list of prices for portraits (in cm):
                    <div class="row p-3">
                        <div class="col-12 col-md-4">
                            <h4>40x30</h4>
                            <ul class="list-unstyled">
                                <li>Single: $930 USD</li>
                                <li>per/Extra: $250 USD</li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <h4>50x60</h4>
                            <ul class="list-unstyled">
                                <li>Single: $2,712 USD</li>
                                <li>per/Extra: $300 USD</li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <h4>55x75</h4>
                            <ul class="list-unstyled">
                                <li>Single: $3,720 USD</li>
                                <li>per/Extra: $350 USD</li>
                            </ul>
                        </div>
                    </div>
                </h4>
                <h4 v-else style="font-weight:400;line-height:2.5rem "  class="text-center">
                    Los retratos son la mayoría de las piezas que recibo como encargo. 
                    Un retrato consiste en la pintura de una sola persona. 
                    Habrá un cargo extra por cada persona extra en el cuadro.<br>
                    Aquí hay una lista de precios de retratos (en cm):
                    <div class="row p-3">
                        <div class="col-12 col-md-4">
                            <h4>40x30</h4>
                            <ul class="list-unstyled">
                                <li>Una persona: $930 USD</li>
                                <li>c/Extra: $250 USD</li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <h4>50x60</h4>
                            <ul class="list-unstyled">
                                <li>Una persona: $2,712 USD</li>
                                <li>c/Extra: $300 USD</li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-4">
                            <h4>55x75</h4>
                            <ul class="list-unstyled">
                                <li>Una persona: $3,720 USD</li>
                                <li>c/Extra: $350 USD</li>
                            </ul>
                        </div>
                    </div>
                </h4>
                <h4 style="font-weight:400;line-height:2.5rem "  class="text-center">
                   <a href="https://forms.gle/myXWKWKPk8w2at4Z7" target="_blank">{{ $store.state.lang == 'en' ? 'Click here' : 'Da clic aquí' }}</a>{{ $store.state.lang == 'en' ? ' to fill out my personalized painting form, and I will connect with you shortly!' : ' para completar mi formulario de pintura personalizado, ¡y me conectaré contigo en breve!' }}
                </h4>
            </div>

            <div data-aos="fade-up" class="pt-2">
                <h1 v-if="$store.state.lang == 'es'" class="title text-center">Contacto</h1>
                <h1 v-else class="title text-center">Contact Me</h1>
            </div>
        
            <div class="col-md-5 col-12 m-auto" data-aos="fade-up" >
                <form @submit.prevent="sendForm()">

                    <ul class="list-unstyled contact-form">
                        <input type="hidden" name="token" v-model="googleToken" />
                        <GoogleCaptcha sitekey="6LeZcSsqAAAAAGy84r_3Vpop-XD1OfZB2_n8Tkca" action="submit" v-on:google_captcha_token="updateToken" ref="captcha"></GoogleCaptcha>

                        <li>
                            <input type="text" class="form-control" v-model="contact.name" :placeholder=" $store.state.lang == 'es' ? 'Nombre':'Name' ">
                        </li>
                        <li>
                            <input type="email" class="form-control" v-model="contact.email" :placeholder=" $store.state.lang == 'es' ? 'Correo':'Email' ">
                        </li>
                        <li>
                            <input type="text" class="form-control" v-model="contact.phone" :placeholder=" $store.state.lang == 'es' ? 'Teléfono' : 'Phone'">
                        </li>
                        <li>
                            <textarea class="form-control" rows="5" v-model="contact.message" :placeholder=" $store.state.lang == 'es' ? 'Mensaje':'Message'"></textarea>
                        </li>
                        <li class="text-center">
                            <button type="submit" class="btn btn-black btn-block">{{$store.state.lang == 'es' ? 'ENVIAR':'SEND' }}</button>
                        </li>
                    </ul>
                </form>
            </div>
        
        </div>
       
    </section>
</template>

<script>
import GoogleCaptcha from 'vue2-recaptchav3';

import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import InstagramIcon from 'vue-material-design-icons/Instagram.vue'
import axios from 'axios'
export default {
    name: 'ContactComponent',
    components: { FacebookIcon, InstagramIcon },
    data(){
        return{
            show:false,
            contact:[],
            googleToken: null,
        }
    },
   
    methods:{
        sendForm(){
            if(this.contact.name == '' || this.contact.email=='' || this.contact.phone == '' || this.contact.message == ''){
                return
            }
            axios.post(this.$url+'/contact',{  accion: 'contact', name: this.contact.name, email: this.contact.email, phone: this.contact.phone, message: this.contact.message  },{
                headers: {'Content-Type' : 'application/json'}
            })
            .then(()=>{
               
            }).catch((err)=>{
                console.log(err)
            })
        },
        updateToken(payload){
          this.googleToken = payload.response_token;
        },
        formSubmit(){
            this.$refs.captcha.generateManualToken().then((token) => {
                //use token here
            });
        }
    }
}
</script>