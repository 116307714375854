import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/Home.vue'
import ArtistView from '@/views/Artist.vue'
import ItemView from '@/views/Item.vue'
import PortfolioView from '@/views/Portfolio.vue'
import BehindTheWorkView from '@/views/BehindTheWork.vue'
import ExhibitionsView from '@/views/Exhibitions.vue'
import ShopView from '@/views/Shop.vue'
import SalesView from '@/views/SalesContact.vue'
import ContactView from '@/views/Contact.vue'
import PolicyView from '@/views/PrivacyPolicy.vue'
import TermsView from '@/views/TermsCondition.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'home',component: HomeView},
  
  {path: '/portfolio',name: 'paintings',component: PortfolioView},
  {path: '/cv',name: 'exhibitions',component: ExhibitionsView},
  {path: '/artwork/:slug',name: 'painting',component: ItemView},
  {path: '/behind-the-work',name: 'behindTheWork',component: BehindTheWorkView},
  {path: '/artist',name: 'artist',component: ArtistView},
  {path: '/shop',name: 'shop',component: ShopView},
  {path: '/shop/:slug',name: 'item',component: SalesView},
  {path: '/contact',name: 'contact',component: ContactView},
  {path: '/privacy-policy',name: 'policy',component: PolicyView},
  {path: '/terms-and-conditions',name: 'terms',component: TermsView}
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
